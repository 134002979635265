import type { Seo } from '@integration-layer/ampliance/schemas/seo-schema.localized'

export type EditorialMeta = {
  type: 'editorial'
  title: string
  brand: string
}

export type PlpMeta = {
  type: 'plp'
  title: string
}

export type PdpMeta = {
  type: 'pdp'
  product: string
  collectionName: string
  gender: string
}

export type BrandHomepageMeta = {
  type: 'brandHomepage'
  slug: string
  brand: string
}

export type ExtraData = EditorialMeta | PlpMeta | PdpMeta | BrandHomepageMeta

export const useAmplienceSeo = async (props: {
  deliveryId?: string
  locale?: string
  vse?: string
  content?: Seo
  extraData?: ExtraData
}) => {
  const { getMetadata } = useSeo()
  const nuxtApp = useNuxtApp()
  const data = props.deliveryId ? await useComponentResponse<Seo>(props) : null

  const metaData = computed(() => {
    // Initialize meta data from 'data', falling back to empty strings if not available
    const metaFromData = {
      title: data?.value?.title ?? '',
      description: data?.value?.description ?? '',
    }

    // If extraData exists, merge its metadata where data is missing
    const metaFromExtra = props.extraData ? getMetadata(props.extraData) : null

    return {
      title: metaFromData.title || metaFromExtra?.title || 'Armani',
      description:
        metaFromData.description || metaFromExtra?.description || 'Armani',
    }
  })

  // Define meta robots from Amplience data
  const metaRobots =
    data?.value?.robotsMetaTags?.map(content => ({
      name: 'robots',
      content,
    })) || []

  nuxtApp.runWithContext(() => {
    useHead({
      title: metaData.value.title,
      meta: [
        { name: 'description', content: metaData.value?.description },
        ...metaRobots,
      ],
    })
  })
}
