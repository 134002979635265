/**
 * Converts a slug string into a title case string
 *
 * @param {string} slug - The slug to convert, e.g. "my-slug"
 *
 * @returns {string} The converted title case string, e.g. "My Slug"
 */
export function convertSlugToTitle(slug: string): string {
  return slug
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
